.write-us-container{
    width: calc(100%);
    height: 620px;
    border: 1px solid #d9d9d9;
    border-radius: 13px;
}
.section-title{
    text-align: center;
}
.form-field-content{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
}
.submit-button{
    width: 90%;
    height: 55px;
    margin-left: 50%;
    transform: translateX(-50%);
    background: #FD375F;
    color: white;
    border: none;
    border-radius: 12px;
    font-size: 17px;
}
.write-us-form{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}
.write-us-content{
    height: 100%;
}
.form-control{
    border: none;
    border-radius: 12px;
    height: 55px;
    outline: none;
    background: #eeeeee;
    padding-left: 10px;
    margin-top: 5px;
}