.contact-info {
  display: flex;
  align-items: center;
  min-width: 158px;
}
.contact-info p,
.contact-info a {
  margin: 0px;
  margin-left: 15px;
}
.info-content {
  color: #2d2d2db2;
  margin-top: 7px !important;
  font-size: 12px;
}
@media(max-width: 600px){
  .contact-info{
    margin-top: 15px;
  }
}