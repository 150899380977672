.additional-services-container{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: auto;
}
.additional-services{
    width: 90%;
    margin: auto;
}
.services-title{
    text-align: center;
    font-weight: 100;
    margin-bottom: 30;
}
@media(max-width: 1260px){
    .additional-services-container{
        flex-direction: column;
    }
}