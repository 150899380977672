.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.sdt-phone a{
  color: black !important;
}
.test-form {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.parent {
  width: 100%;
  display: flex;
  justify-content: center;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 500px) {
  .main-container {
    padding-top: 68px !important;
  }
}

@media(max-width: 1150px){
  .contact-responsive{
    flex-direction: column;
  }
  .set-responsive{
    width: 100% !important;
  }
}