.form-text{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form-container{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.consultation-form-container{
    margin: 120px auto;
    width: 90%;
}
.consultation-form-content{
    width: 80%;
    border-radius: 30px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F4F6F9;
    padding: 20px 30px;
}
.consultation-form{
    background: white;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 50px;
    border-radius: 16px;
}
.form-control{
    width: 100%;
    height: 45px;
    outline: none;
    font-size: 17px;
    border: 1px solid #E8E8E8;
    margin-top: 30px;
    border-radius: 12px;
    padding-left: 10px;
}

.form-control:focus{
    border: 1px solid #c9ebc6;
}

.checkbox-content{
    margin: 20px 0px;
}
.form-label{
    margin-left: 10px;
    color: #707070;
}
.submit-button, .submit-button-disabled{
    background: #FD375F;
    color: white;
    border: 1px solid #FD375F;
    border-radius: 12px;
    width: 100%;
    height: 50px;
    font-size: 18px;
    margin-top: 50px;
    cursor: pointer;
}
.form-control-title{
    width: 550px;
    font-size: 45px;
    line-height: 70px;
}
.form-control-text{
    width: 550px;
    line-height: 23px;
    color: #707070;
}
.submit-button-disabled{
    border: 1px solid #fa6d89;
    background-color: #fa6d89;
}
.show-modal{
    cursor: pointer;
}
@media(max-width: 1440px){
    .consultation-form-content{
        flex-direction: column;
    }
    .form-container{
        margin-top: 40px;
    }
}

@media(max-width: 1050px){
    .form-container{
        width: 100%;
    }
    .consultation-form{
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    .form-control-title{
        font-size: 30px;
        line-height: 45px;
        width: 100%;
    }
    .form-text{
        width: 100%;
    }
    .form-control-text{
        width: 100%;
    }
}
@media(max-width: 506px){
    .form-control-title{
        font-size: 22px;
        line-height: 35px;
    }
}