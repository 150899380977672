@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Roboto&display=swap');

.our-offers-container{
    width: 90%;
    margin: auto;
    margin-top: 130px;
}
.our-offers-title{
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
}
.offer-item{
    width: 22%;
}
.offers-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media(max-width: 1090px){
    .offers-list{
        flex-direction: column;
    }
}