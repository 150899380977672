.vehicles-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
}
.load-more{
    width: 90%;
    margin: 40px auto 40px auto;
    display: flex;
    justify-content: center;
}
.load-more-button{
    width: 250px;
    height: 40px;
    background: #FD375F;
    color: white;
    border: #FD375F 1px solid;
    border-radius: 10px;
    font-size: 17px;
    cursor: pointer;
}

@media(max-width: 574px){
    .vehicles-list{
      justify-content: center !important;
    }
  }