.select-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    width: calc(100% - 20px);
    height: 50px;
    border: 1px solid #F4F6F9;
    background: #F4F6F9;
    border-radius: 12px;
    color: #797979;
    cursor: pointer;
}
.selected-option{
    margin: 0;
}
.dropdown-list{
    list-style-type: none;
    padding: 0;
    margin-top: 0px;
}
.dropdown-list li{
    padding: 12px 0px 12px 10px;
    cursor: pointer;
}
.dropdown-list li:hover{
    background: #FD375F;
    color: white;
}
.select-dropdown{
    background: white;
    height: fit-content;
    max-height: 250px;
    overflow: auto;
    width: 350px;
    position: absolute;
    border-radius: 0px 0px 8px 8px;
    box-shadow: 0px 6px 17px -4px grey;
}
.select-container{
    width: 352px
}

.select-container{
    margin-top: 30px;
}

@media(max-width: 1321px){
    .select-container{
        margin-top: 30px;
    }
}