.images-slideshow-container{
    width: 90%;
    max-width: 1050px;
    margin: 70px auto 50px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.main-image-container{
    border-radius: 20px;
    height: 500px;
    width: calc(100% - 230px);
    max-width: 800px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.prev, .next{
    background: white;
    border: none;
    padding: 10px 15px;
    border-radius: 50%;
}
.prev:hover, .next:hover{
    transform: scale(1.2);
    transition: 0.5s;
    cursor: pointer;
}
.prev{
    margin-left: 20px;
}
.next{
    margin-right: 20px;
}
@media(max-width: 870px){
    .images-slideshow-container{
        flex-direction: column-reverse;
    }
    .main-image-container{
        width: 100%;
    }
}
@media(max-width: 500px){
    .main-image-container{
        height: 280px;
    }
}