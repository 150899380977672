.popup-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -145px;
}
.popup-background {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 111111;
}
.popup-box-content {
  position: fixed;
  z-index: 111111111;
  background: white;
  border-radius: 15px;
  width: 50%;
  max-width: 600px;
  height: 330px;
}
.vehicle-image-on-modal {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.title-on-modal {
  text-align: center;
  margin-top: 50px;
}
.hr {
  background: #d9d9d9;
  width: 100%;
  height: 1px;
  margin: 10px 0px;
}
@media(max-width: 730px){
  .popup-box-content{
    width: 95% !important;
  }
}