.popup-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -145px;
}
.popup-background {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 111111;
}
.popup-box-content {
  position: fixed;
  z-index: 111111111;
  background: white;
  border-radius: 15px;
  width: 50%;
  max-width: 600px;
  height: 530px;
}
.vehicle-image-on-modal {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.title-on-modal {
  text-align: center;
  margin-top: 50px;
}
.vehicle-info {
  display: flex;
  width: 90%;
  align-items: center;
  margin: auto;
}
.vehicle-details {
  line-height: 10px;
  margin-left: 20px;
}
.vehicle-model-on-modal {
  font-size: 17px;
}
.vehicle-location {
  font-size: 12px;
  font-family: "FiraGO" !important;
  font-weight: 100 !important;
}
.location {
  color: #fd375f;
}
.hr {
  background: #d9d9d9;
  width: 100%;
  height: 1px;
  margin: 10px 0px;
}
.form-input {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}
.form-label {
  margin-bottom: 5px;
  margin-top: 18px;
  font-size: 14px;
}
.form-control {
  background: #fafafa;
  border-radius: 8px;
  border: 1px solid #fafafa;
  padding-left: 10px;
  height: 35px;
  font-size: 16px;
  outline: none;
  color: gray
}
.submit-button-box{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto 0px auto;
}
.submit-button{
    width: 100%;
    height: 39px;
    background: #fd375f;
    border: 1px solid #fd375f;
    border-radius: 10px;
    color: white;
    font-size: 17px;
    cursor: pointer;
}

@media(max-width: 730px){
  .popup-box-content{
    width: 95% !important;
  }
}