.admin-auth-container{
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.auth-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    box-shadow: 0px 0px 5px gray;
    padding: 30px;
}
.input-form{
    margin-bottom: 15px;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 12px;
    height: 30px;
    padding-left: 10px;
    font-size: 17px;
}
.input-form:focus{
    outline: 1px solid #FD2447;
}
.submit-btn{
    cursor: pointer;
    width: 150px;
    height: 35px;
    color: white;
    font-weight: bold;
    font-size: 17px;
    background: #FD2447;
    border: 1px solid #FD2447;
    border-radius: 15px;
}
.title{
    font-size: 18px;
}
.auto-market-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 423px);
    width: 100%;
    
}
.auto-market-container p{
    font-size: 55px;
    color: #FD2447;
    font-weight: bold;
}