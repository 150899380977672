.baner-container{
    width: 100%;
    height: 900px;
    background-image: url('/public/images/baner.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-end;
}
h1{
    margin: 0px;
}
.baner-content{
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media(max-width: 500px){
    .baner-container{
        height: 440px !important;

    }
}