.search-form-container{
    width: 90%;
    height: 300px;
    background: white;
    border-radius: 12px;
    margin: auto;
    margin-top: -80px;
}
.search-block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
}
.search-form{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.search-button{
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
}
.submit-button{
    width: 350px;
    height: 50px;
    background: #FD375F;
    margin-left: 20px;
    border: 1px solid #FD375F;
    color: white;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
}
.vertical-line{
    height: 60px;
    width: 1px;
    background: #F4F6F9;
}
@media(max-width: 1321px){
    .search-block{
        justify-content: space-around;
    }
    .search-form-container{
        height: 450px;
    }
    .search-button{
        justify-content: center;
    }
    .submit-button{
        margin-left: 0px;
    }
}
@media (max-width: 884px) {
    .search-form-container{
        height: 650px;
    }
}