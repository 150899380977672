@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

.color-red{
    color: #FD2447;
}
a{
    text-decoration: none;
    color: white;
}
.logo{
    font-size: 36px;
    font-family: 'Open Sans', sans-serif;
    color: white;
}
.active{
    border-bottom: #FD2447 2px solid;
    padding-bottom: 4px;
}
.header-content{
    width: 100vw;
    position: fixed;
    z-index: 11111;
    background: #272726;
    padding: 12px 0px;
}
.header-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: auto;
}
.navigation-list{
    display: flex;
    align-items: center;
    margin: 6px 0px 0px 0px;
    padding: 0px;
}
.navigation-list li{
    list-style-type: none;
    margin: 0px 10px;
}
.header-controls, .languages-container{
    display: flex;
    align-items: center;
}
.languages-container{
    margin-left: 50px;
    flex-direction: column;
}
.current-language{
    margin-right: 17px;
    color: white;
}
.current-language-container{
    display: flex;
    align-items: center;
}
.languages-dropdown{
    position: absolute;
    list-style-type: none;
    margin-top: 50px;
    padding: 0px;
    background-color: #FD2447;
    border-radius: 8px;
    border: 1px solid #FD2447;
    transition: 0.5s;
}
.languages-dropdown li{
    padding: 10px 40px;
    margin: 6px 0px;
    color: white;
    cursor: pointer;
}
.languages-dropdown li:hover{
    color: #FD2447;
    background: white;
}
.search-button{
    background: none;
    border: none;
    padding: 0;
    height: 24px;
    cursor: pointer;
}
.search-input{
    display: flex;
    align-items: center;
    border: 1px solid white;
    padding: 5px 7px;
    border-radius: 20px;
}
.hide-search-input{
    width: 0px;
    transition: 0.5s;
    border: none;
    padding: 0;
    background: none;
}
.header-form-control{
    width: 200px;
    transition: 0.5s;
    outline: none;
    font-size: 15px;
    border: none;
    background: none;
    color: white;
}
.noheight{
    height: 0px !important;
}
@media(max-width: 1300px){
    .navbar{
        display: block;
        position: absolute;
        width: 100%;
        margin-left: -5%;
        margin-top: 312px;
        height: fit-content;
        transition: 0.5s;
    }
    .navbar-desktop{
        display: none !important;
    }
    .navigation-list{
        display: block;
        background-color: #272726;
        margin-top: 0px !important;
    }
    .navigation-list li{
        text-align: center;
        margin: 0;
        width: 100%;
        height: 49px;
        display: flex;
        align-content: center;
        justify-content: center;
    }
    .navigation-list li a{
        padding: 15px 0px;
    }
    .active{
        padding: 15px 0px;
        border-bottom: none;
        background: #FD2447;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
@media (max-width:1321PX) {
    .languages-container{
        margin-left: 20px;
        margin-right: 15px;
    }
    .header-form-control{
        width: 100px;
    }
}
@media(max-width: 500px){
    .logo{
        font-size: 25px;
    }
}