.social-block-container{
    margin-top: 20px;
    width: 100%;
    height: 298px;
    border: 1px solid #d9d9d9;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.social{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.social-name{
    margin-bottom: 10px !important;
}
.social-user{
    margin: 0;
}