.offer-content{
    width: 300px;
    min-width: 300px !important;
    border: 1px solid #E8E8E8;
    padding: 15px;
    border-radius: 30px;
    height: 360px;
    margin-top: 40px;
}
.icon-circle{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.offer-title{
    font-size: 19px;
    font-family: 'Roboto', sans-serif;
    margin-top: 40px;
    margin-bottom: 25px;
}
.offer-text{
    font-size: 17px;
    color: gray;
    font-family: 'Roboto', sans-serif;
    width: 80%;
}