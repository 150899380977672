.slide-down-images-container{
    width: 150px;
    height: 500px;
    overflow: hidden;
}
.slide-down-images-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.slide-down-images-content::-webkit-scrollbar {
    display: none;
  }
.slide-down-images-content img{
    width: calc(100% - 2px);
    border-radius: 20px;
    margin-top: 10px;
    cursor: pointer;
    border: 1px solid white;
}
.slide-down-images-content img:first-child{
    margin-top: 0px;
}
.slide-down-images-content img:hover{
    border: 1px solid #FD375F;
}
.slider {
    position: relative;
    border-radius: 12px;
    margin-bottom: 40px;
  }
  .slider img {
    object-fit: cover;
  }
  .slider :global(.control-dots) {
    bottom: -30px;
  }
  .slider :global(.carousel-slider) {
    overflow: unset;
  }
  .slider :global(.dot) {
    background: #66CAC8 !important;
  }
  .slider :global(.dot.selected) {
    width: 16px !important;
    border-radius: 4px;
  }
  .slider :global(.slider-wrapper) {
    border-radius: 12px;
  }
  .slider .radius {
    border-radius: 12px;
  }
  .slider .scroll-arrow {
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #404B53;
    position: absolute;
    top: 50%;
    cursor: pointer;
    z-index: 1;
    transform: translateY(-50%);
  }
  @media (max-width: 1024px) {
    .slider .scroll-arrow {
      display: none;
    }
  }
  .slider .scroll-arrow img {
    width: 12px;
    height: 14px;
  }
  .slider .scroll-left {
    left: 5px;
  }
  .slider .scroll-right {
    right: 5px;
    transform: rotateY(190deg) translateY(-50%);
  }

  @media(max-width: 870px){
    .slide-down-images-container{
        height: 140px;
        width: 100%;
        margin-top: 20px;
    }
    .slide-down-images-content img{
        margin-top: 0px;
    }
    .slide-down-images-content{
        flex-direction: row;
    }
    .main-image-container{
        width: 100%;
    }
}
@media(max-width: 500px){
    .main-image-container{
        height: 270px;
    }
    .slide-down-images-container{
        height: 90px !important;
    }
}