.loader-container{
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 20px 0px;
}
.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid rgb(78, 78, 78);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }

.input-block{
    width: 352px;
    display: flex;
    align-items: center;
}
.price-from, .price-to{
    width: calc(40% - 2px);
    outline: none;
}
.currency{
    width: 20%;
}
.price-from{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    height: 48px;
    border: 1px solid #F4F6F9;
    background: #F4F6F9;
    padding-left: 10px;
}
.price-to{
    height: 48px;
    border: 1px solid #F4F6F9;
    background: #F4F6F9;
    padding-left: 10px;
    margin-left: 4px;
}
.currency{
    height: 52px;
    background: #FD375F;
    border: 1px solid #FD375F;
    color: white;
    font-size: 18px;
    cursor: pointer;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}