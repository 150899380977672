.description-content{
    width: 90%;
    margin: 50px auto;
}
.description-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.description-block{
    padding: 0;
    list-style-type: none;
    width: calc(50% - 10px);
    border: #D9D9D9 1px solid;
    border-radius: 10px;
}
.name, .value{
    width: 50%;
}
.flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.description-block li{
    padding: 10px 0px 10px 40px;
    border-bottom: #D9D9D9 1px solid;
}
.name{
    color: #666666;
    font-weight: 100;
    font-family: 'Roboto', sans-serif !important;
}

@media(max-width: 1150px){
    .name, .value{
        width: fit-content !important;
    }
    .description-block li{
        padding: 10px 20px 10px 20px;
    }
}

@media(max-width: 830px){
    .description-container{
        flex-direction: column;
    }
    .description-block{
        width: 100%;
    }
}