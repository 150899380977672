.select-dropdown-list {
  display: flex;
  justify-content: space-between;
  height: 320px;
  position: absolute;
  background: white;
  width: 330px;
  padding: 0px 10px;
  flex-wrap: wrap;
}
.vertical-line {
  height: 250px;
  width: 1px;
  background: rgb(207, 207, 207);
}
.hr{
    width: 100%;
    height: 1px;
    background: rgb(207, 207, 207);
}
.list-header{
    text-align: center;
}
.dropdown-list-one,
.dropdown-list-two {
  height: 250px;
  overflow: auto;
  margin-top: 0px;
  padding-left: 0px;
  width: calc(50% - 1px);
}
.dropdown-list-one::-webkit-scrollbar, .dropdown-list-two::-webkit-scrollbar {
  display: none;
}
.dropdown-list-one li,
.dropdown-list-two li{
    padding: 12px 0px 12px 10px;
    text-align: left;
}
.dropdown-list-one li:hover,
.dropdown-list-two li:hover{
    background: #fa4d70;
    cursor: pointer;
    color: white;
}
.choose{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.choose-button{
    height: fit-content;
    font-size: 16px;
    padding: 5px 15px;
    background: #FD375F;
    border: #FD375F 1px solid;
    border-radius: 6px;
    color: white;
    cursor: pointer;
}
.active{
  background: #FD375F;
  color: white;
}