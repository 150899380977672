.navbar-links{
    display: flex;
    align-items: center;
    list-style-type: none;
}
.navbar-links li{
    margin-right: 20px;
    cursor: pointer;
}
.table-container{
    list-style-type: none;
    width: fit-content;
    margin-bottom: 40px;
}
.table-container li{
    display: flex;
    align-items: center;
    padding: 15px 15px 15px 10px;
    border-bottom: 1px solid rgb(226, 226, 226);
}
.table-container li:first-child{
    background-color: #fd375f;
    height: 30px;
    color: white;
}
.col{
    margin-right: 30px;
}
.admin-panel{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 423px);
}