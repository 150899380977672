.vehicle-box{
    width: 22%;
    min-width: 250px;
    border: 1px solid #E8E8E8;
    border-radius: 18px;
    background: white;
    margin-top: 40px;
}
.vehicle-image{
    width: 100%;
    height: 220px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 18px 18px 0px 0px;
}
.vehicle-info{
    display: flex;
    align-items: center;
}
.circle{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #707070;
    margin: 0px 10px;
}
.vehicle-condition{
    color: #707070;
    width: fit-content;
    font-size: 14px !important;
    margin: 0;
}
.vehicle-millage{
    color: #707070;
    margin: 0;
}

p{
    font-family: 'Roboto', sans-serif;
}
.vehicle-price{
    font-weight: 400;
    font-size: 17px;
    color: black !important;
}
.vehicle-description{
    padding-left: 10px;
    padding-bottom: 20px;
}
.sell-type{
    font-size: 15px;
    font-weight: 100;
    padding: 10px 20px;
    border: 1px solid #FD375F;
    border-radius: 10px;
    width: fit-content;
    color: #4A4A4A;
}
.title-text{
    color: black !important;
    font-size: 17px;
    font-weight: 400;
}
@media (max-width: 1400px){
    .vehicle-image{
        height: 180px;
        transition: 0.5s;
    }
}