.contact-us-container {
  width: 100%;
}
.section-title {
  text-align: center;
}
.contact-us-content {
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 13px;
  padding-bottom: 2em;
}
.contact-item-content {
  width: 90%;
  margin: auto;
}
.location, .location-usa{
  color: #fd375f;
  font-weight: 100;
  margin-block-start: 2em;
}
.item-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.hr{
    width: 100%;
    height: 1px;
    background: #D9D9D9;
    margin-block-start: 2em;
}
@media(max-width: 600px){
  .item-list{
    justify-content: space-around;
  }
}