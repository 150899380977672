.section-title {
  font-weight: 200;
  text-align: center;
}
.registration-form-baner {
  background-image: url("/public/images/rectangle.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: calc(50% - 10px);
  height: 400px;
  border-radius: 20px;
}
.dealers-registration-container {
  width: 90%;
  margin: auto;
}
.registration-form-content {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text-baner-container {
  background: rgba(0, 0, 0, 0.99);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 20px;
  opacity: 0.6;
  height: 100%;
}
.text-on-baner {
  text-align: center;
  width: 60%;
  color: white;
  line-height: 30px;
  font-size: 18px;
  font-weight: bold;
}
.registration-form {
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  width: calc(50% - 10px);
  height: 400px;
}
.input-field-block{
    display: flex;
    flex-direction: column;
}
.fegistration-form-content{
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}
.input-label{
    font-size: 13px;
    font-weight: bold;
}
.form-control{
    height: 40px;
    margin-top: 5px;
    padding-left: 10px;
    outline: none;
    background: #efefef;
    border-radius: 8px;
    border: 1px solid #efefef;
}
.submit-button{
    background: #FD375F;
    color: white;
    height: 45px;
    border: 1px solid #FD375F;
    border-radius: 8px;
    font-size: 17px;
}
@media(max-width: 750px){
  .registration-form-content {
    flex-direction: column;
  }
  .registration-form-baner{
    width: 100%;
  }
  .registration-form{
    width: 100%;
    margin-top: 30px;
  }
}