@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Roboto&display=swap');

.baner-text-container{
    width: 50%;
    min-width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.baner-title{
    font-size: 50px;
    line-height: 70px;
}
.baner-title, .baner-text{
    color: white;
    font-family: 'Roboto', sans-serif;
}
.baner-text{
    font-size: 17px;
    font-weight: 100;
    color: #E3E3E3;
    width: 350px;
    line-height: 25px;
}
.consultation-button{
    width: fit-content;
    padding: 15px 40px;
    border-radius: 20px;
    border: 1px solid white;
    font-size: 18px;
    font-family: 'firaGo';
    margin-top: 20px;
}
.consultation-button{
    cursor: pointer;
}
@media(max-width: 500px){
    .baner-title{
        font-size: 27px;
        line-height: 35px;
    }
    .baner-text{
        margin: 10px 0px !important;
    }
}