.car-require-container {
  width: 90%;
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 10px 0px;
}
.manager-section{
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.active{
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: #3FFF47;
}
.manager-name{
    margin: 0px 25px;
}
.car-require-button{
    background: #FD375F;
    border: 1px solid #FD375F;
    color: white;
    padding: 10px 60px;
    margin-right: 20px;
    font-weight: bold;
    border-radius: 10px;
    font-size: 17px;
    cursor: pointer;
}
@media(max-width: 575px){
    .car-require-container{
        flex-direction: column;
    }
    .car-require-button{
        margin-top: 20px;
    }
}