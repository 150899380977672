.social-media-container{
    width: 75px;
    height: 230px;
    background: #FD375F;
    border-radius: 20px 0px 0px 20px;
}
.social-media-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}