.hoverClass:hover {
  border: 1px solid white !important;
  border-radius: 50%;
  transition: 0.5s;
}

.hoverClass {
  padding: 10px;
  border-radius: 50%;
}
.icon-in-contacts {
  width: fit-content;
  border-radius: 50%;
  padding: 7px;
}
.bar-icon {
  fill: white !important;
  display: none;
}

@media (max-width: 1300px) {
  .bar-icon {
    display: block;
  }
}
