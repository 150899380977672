.footer-container {
  width: 100%;
  height: 350px;
  background: #272726;
}
.footer-content {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  padding-top: 30px;
}
.footer-list {
  list-style-type: none;
  padding-left: 0px;
}
.footer-list li {
  margin: 16px 0px;
  color: #e8e8e8;
}
.footer-section-title {
  color: white;
}
.footer-social-networks {
  display: flex;
  align-items: center;
}
.social-circle {
  background: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subscribtion-form {
  display: flex;
  flex-direction: column;
}
.form-control {
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #414141;
  font-size: 17px;
  padding-bottom: 5px;
  color: white;
  outline: none;
}
.subscribtion-button {
  margin-top: 35px;
  border: 1px solid #fd375f;
  border-radius: 9px;
  background: none;
  color: white;
  padding: 15px 0px;
  font-size: 17px;
  cursor: pointer;
}
.subscribtion-button:hover{
    background: #fd375f;
    transition: 0.5s;
}
.footer-hr{
    width: 90%;
    height: 1px;
    background: #3a3a3a;
    margin: auto;
    margin-top: 20px;
}
@media(max-width: 1030px){
  .footer-content{
    flex-wrap: wrap;
  }
}
@media(max-width: 950px){
  .footer-container{
    height: 450px
  }
}
@media(max-width: 699px){
  .footer-container{
    height: 480px
  }
}
@media(max-width: 580px){
  .footer-content{
    flex-direction: column;
    align-items: center;
  }
  .footer-content li, .footer-content a, .footer-content h3{
    text-align: center;
  }
  .footer-container{
    height: 850px
  }
  .footer-social-networks{
    justify-content: center;
  }
}