.vehicles-list-container {
  width: 90%;
  margin: 120px auto;
}
.vehicles-list-title {
  font-family: "Roboto", sans-serif;
  font-weight: 200;
  margin-bottom: 40px;
}
.vehicles-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}
.autocatalogue-button{
    background: #FD375F;
    border: 1px solid #FD375F;
    border-radius: 12px;
    color: white;
    padding: 18px 50px;
    cursor: pointer;
    margin-top: 70px;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    margin-left: 50%;
    transform: translateX(-50%);
}
@media(max-width: 574px){
  .vehicles-list{
    justify-content: center !important;
  }
}