.service-content {
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  width: calc(50% - 40px);
  display: flex;
  padding-left: 30px;
  height: 125px;
}
.auction-services-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: auto;
}
.service-text-container{
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.service-title{
    font-size: 17px;
    margin-top: 0px;
}
.service-description{
    color: #0000005e;
    margin-top: 0px;
    font-size: 12px;
    max-width: 500px;
    font-family: 'FiraGO' !important;
}

@media(max-width: 700px){
    .auction-services-container{
        flex-direction: column;
    }
    .service-content{
        width: 90%;
        margin-bottom: 20px;
    }
}