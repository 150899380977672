.vehicle-info-header-container{
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.vehicle-price-info{
    background: rgba(253, 55, 95, 0.03);
    border: 1px solid rgba(253, 55, 95, 0.5);
    display: flex;
    align-items: center;
    padding: 0px 15px;
    border-radius: 10px;
    justify-content: space-between;
}
.gel-button, .usd-button{
    margin: 0px 7px;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 17px;
}
p{
    font-family: 'firaGo' !important;
    font-size: 14px;
}
span{
    color: #FD375F;
}
.vehicle-price-info h2{
    margin: 10px 0px;
    font-size: 20px;
}