.section-title{
    text-align: center;
    font-weight: 100;
    margin-top: 70px;
}
.services-description{
    text-align: center;
    color: #666666;
}
.services-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    margin: auto;
}