.section-title{
    font-weight: 100;
    text-align: center;
    margin-bottom: 50px;
}
.frequency-questions-container{
    margin: 90px;
}
@media(max-width: 555px){
    .frequency-questions-container{
        margin: 90px 20px 90px 20px;
    }
}